.release-note-main-div {
  padding: 10px 20px;
  border: solid 1px #eee;
  border-radius: 1px;
}

.release-note-title {
  margin: 0;
  padding: 0;
}

.release-note-separator-div {
  width: 100%;
  height: 1px;
  background-color: #e0e0e0;
  margin: 20px 0;
}
